import UserResults from "../components/users/UserResults"
import UserSearch from "../components/users/UserSearch"

function Home() {
  return (
    <>
      <UserSearch />
      <UserResults />
    </>
  )
}
export default Home